var slick = require('slick-carousel');

$(function() {
    if ($('.generated-link-item').length > 0) {
        if ($('.generated-link-item').hasClass('expired-item')) {
            $('.generated-link-item').on( 'click', function(e) {
                e.preventDefault();
                e.stopPropagation();
            });
        }
    }

    $('.send-generated-links-section .form-send form').on('submit', function (e) {
        e.preventDefault();

        let form = $(e.currentTarget);

        form.find('button.btn i').removeClass('fa-envelope').addClass('fa-spinner fa-spin');

        $.ajax({
            url: form.attr('action'),
            method: 'POST',
            data: form.serialize(),
            success: function(data) {
                // console.log('success > data', data);
                form.fadeOut().siblings('.alert-success').removeClass('d-none');
            },
            error: function(jqXHR) {
                // console.log('error > jqXHR', jqXHR);
                form.fadeOut().siblings('.alert-danger').removeClass('d-none');
            }
        });
    });
});

$(function () {
    $(document).delegate('.add-to-link-list', 'click touch', function (e) {
        e.preventDefault();

        let id = $(this).data('id');
        let current_element = $(this);
        let url = '';

        current_element.addClass('adding').find('i').addClass('fa-spin');

        let add = false;
        if ($(this).hasClass('added')) {
            url = "/user-area/favorites/ajax/delete/" + id;
        } else {
            add = true;
            url = "/user-area/favorites/ajax/add/" + id;
        }

        $.ajax({
            type: "GET",
            url: url,
            success: function (data) {
                if (add) {
                    current_element.removeClass('adding').addClass('added').find('i').removeClass('fa-spin');
                } else {
                    current_element.removeClass('added adding').find('i').removeClass('fa-spin');
                }

                checkIfAddedToListExists();
            }
        });
    });

    $(document).delegate('.remove-from-link-list', 'click', function (e) {
        e.preventDefault();

        let id = $(this).data('id');
        let url = '/user-area/favorites/ajax/delete/' + id;

        $.ajax({
            type: "GET",
            url: url,
            success: function (data) {
                $('.incognito-' + id).hide().remove();
            }
        });
    });

    if ($('.user-dashboard-link').length > 0) {
        window.addEventListener('propertiesLoadedEvent', function (e) {
            checkIfAddedToListExists();
        });
    }
});

function initializeCarousel(element) {
    $(element + ':not(.slick-initialized)').slick({
        'prevArrow': '<span class="slick-prev slick-nav"><i class="fas fa-angle-left"></i></span>',
        'nextArrow': '<span class="slick-next slick-nav"><i class="fas fa-angle-right"></i></span>',
    });
}

function displayGenerateLinksLink(visibility) {
    if (!visibility) {
        $('.generate-links-page-link-wrapper').removeClass('visible');
    } else {
        $('.generate-links-page-link-wrapper').addClass('visible');
    }
}

function checkIfAddedToListExists() {
    $.ajax({
        type: "GET",
        url: '/user-area/favorites/ajax/get',
        success: function (data) {
            if (data.data !== undefined) {
                if (data.data.length > 0) {
                    displayGenerateLinksLink(true);

                    data.data.forEach(function(value) {
                        if ($('#property-' + value).length > 0 && !$('#property-' + value + ' .add-to-link-list').hasClass('added')) {
                            $('#property-' + value + ' .add-to-link-list').addClass('added');
                        }
                    });
                }
            } else {
                displayGenerateLinksLink(false);
            }
        }
    });
}